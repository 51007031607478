/* eslint-env browser */
/* global Glide */

// Side Menu Slide
const sideMenuButton = document.querySelector('.js-side-menu-button');
const sideMenu = document.querySelector('.js-side-menu');
const html = document.getElementsByTagName('html')[0];

if (sideMenuButton) {
  sideMenuButton.onclick = () => {
    sideMenuButton.classList.toggle('is-active');
    sideMenu.classList.toggle('is-active');
    html.classList.toggle('no-scroll');
  };
}

// Carousel
const glide = document.querySelector('.glide');

if (glide) {
  new Glide('.glide', {
    type: 'slider',
    startAt: 0,
    perView: 1,
    gap: 0,
    hoverpause: false,
  }).mount();
}
